// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
  Auth0Client,
  User as Auth0User,
  GetTokenSilentlyOptions,
  RedirectLoginOptions,
  RedirectLoginResult,
  createAuth0Client,
} from '@auth0/auth0-spa-js';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EMPTY, Observable, ReplaySubject, from, iif, of, throwError, zip } from 'rxjs';
import { catchError, concatMap, defaultIfEmpty, first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { when } from 'src/app/operators/rxjs/when';
import { AlertService } from 'src/app/shared/alert.service';
import { AccessToken, decodeJwtToken } from 'src/app/shared/util';
import { NotAuthorisedModalComponent } from 'src/app/ui/modal/not-authorised-modal/not-authorised-modal.component';
import { User } from 'src/app/user/user.model';
import { environment } from 'src/environments/environment';

/**
 * A user needs to explicitly reauthenticate. We cannot try to authenticate for the user silently.
 */
export const USER_EXPLICIT_REAUTH = 'USER_EXPLICIT_REAUTH' as const;

/**
 * App state at the point just before the user is redirected to the Auth0 login page.
 */
export interface AppState {
  /**
   * The URL to redirect to after successful login at Auth0 and Auth0 redirects back to our app.
   */
  target: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  auth0Client$: Observable<Auth0Client> = from(
    createAuth0Client({
      domain: environment.auth.domain,
      clientId: environment.auth.clientId,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: environment.auth.audience,
      },
      cacheLocation: 'localstorage',
      useRefreshTokens: true,
    }),
  ).pipe(
    shareReplay(1),
    catchError((err: HttpErrorResponse) => {
      if (err.error === 'unauthorized' || err.error === 'access_denied') {
        const modalService = this.getModalService();
        modalService.show(NotAuthorisedModalComponent);
        return EMPTY;
      }
      return throwError(() => err);
    }),
  );

  isAuthenticated$ = this.auth0Client$.pipe(concatMap(client => from(client.isAuthenticated())));

  handleRedirectCallback$ = this.auth0Client$.pipe(concatMap(client => from(client.handleRedirectCallback())));

  private readonly userProfileSubject$ = new ReplaySubject<UserProfile>(1);

  // Ignore warning; need this public variable to be after private variable has been declared.
  // eslint-disable-next-line @typescript-eslint/member-ordering
  userProfile$ = this.userProfileSubject$.asObservable();

  constructor(
    protected httpClient: HttpClient,
    protected alertService: AlertService,
    protected router: Router,
    private injector: Injector,
  ) {}

  handleAuthCallback$(): Observable<{ authenticated: boolean; targetUrl?: string } | typeof USER_EXPLICIT_REAUTH> {
    // Convenience function to initialise the user in our app.
    const _initUser$ = (result?: RedirectLoginResult<AppState>) =>
      this.isAuthenticated$.pipe(
        first(),
        concatMap(authenticated => this.initUser$(authenticated)),
        map(authenticated => {
          if (authenticated === USER_EXPLICIT_REAUTH) {
            return USER_EXPLICIT_REAUTH;
          }

          let targetUrl = '';
          if (result && result.appState) {
            // trim to ensure no whitespaces. If value is all whitespaces, treat as empty string.
            // result.appState.target can be the empty string "". In this case, we want to redirect to the root path.
            targetUrl = (result.appState.target && result.appState.target.trim()) || '/';
          }
          return { authenticated, ...(targetUrl ? { targetUrl } : {}) };
        }),
      );

    return of(window.location.search).pipe(
      concatMap(params =>
        iif(
          () => params.includes('error='),
          of(null).pipe(
            tap(() => {
              // Remove the connection cookie in case it was the culprit
              this.removeConnection();
              const p = new URLSearchParams(params);
              this.alertService.showError(p.has('error_description') ? p.get('error_description') : 'Error');
              this.getModalService().show(NotAuthorisedModalComponent);
            }),
            concatMap(() => EMPTY),
          ),
          of(params),
        ),
      ),
      concatMap(params =>
        iif(
          () => params.includes('code=') && params.includes('state='),
          this.handleRedirectCallback$.pipe(
            concatMap(result => _initUser$(result)),
            // Invalid state will occur on the first redirect so handle it gracefully.
            // It could also potentially occur through other ways.
            catchError(() =>
              this.isAuthenticated$.pipe(
                first(),
                map(authenticated => ({ authenticated })),
              ),
            ),
          ),
          _initUser$(),
        ),
      ),
    );
  }

  isAdmin$(): Observable<boolean> {
    return this.userProfile$.pipe(map(user => user.isAdmin));
  }

  /**
   * Get Auth0 access token. Verifies that the user is authorised to access API. If there is no valid access token due
   * to an invalid or missing refresh token, this method will logout the user and the returned observable will complete
   * without emitting any value.
   *
   * @param options optional
   * @param decoded whether to decode the token before returning
   * @param logout whether to logout the user if there is no valid access token. This will log user out and redirect the user to the login page. In most cases, this should be true (the default). In the rare case, use false and the caller decides when to logout the user.
   * @returns Observable
   */
  getAccessToken$(options: GetTokenSilentlyOptions, decoded: true, logout?: boolean): Observable<AccessToken | never>;
  getAccessToken$(options?: GetTokenSilentlyOptions, decoded?: false, logout?: boolean): Observable<string | never>;
  getAccessToken$(
    options?: GetTokenSilentlyOptions,
    decoded: boolean = false,
    logout: boolean = true,
  ): Observable<string | AccessToken | never> {
    return this.auth0Client$.pipe(
      concatMap(client => from(client.getTokenSilently(options))),
      concatMap(token => iif(() => decoded, of(decodeJwtToken(token)), of(token))),
      catchError((e: HttpErrorResponse) => {
        if (e.error === 'invalid_grant' || e.error === 'missing_refresh_token' || e.error === 'login_required') {
          // Specific errors from Auth0 after calling their API to get access token.
          //
          // 'invalid_grant':
          //  - Auth0 received an invalid refresh token from us. Refresh token most likely has expired.
          //  The error object will also have the following properties:
          //    - error.message             -> 'Unknown or invalid refresh token.'
          //    - error.error_description   -> 'Unknown or invalid refresh token.'
          //
          // 'missing_refresh_token':
          // - We turned on `useRefreshTokens` but the user's browser cached data have no refresh token to begin with.
          //   - error.message              -> 'Missing refresh token.'
          //   - error.error_description    -> 'Missing refresh token.'
          //
          // 'login_required':
          // - The user is required to login explicitly again, can't do it silently. No valid session available.
          //
          //
          // Can't really do anything more. User has to reauthenticate explicitly.
          console.log(`[AUTH] getAccessToken$. Auth0 responded with: "${e.error}". Message: "${e.message}"`);
          console.log('[AUTH] User has to reauthenticate explicitly.');

          // Always ensure we return the observable complete notification without emitting any values. Callers rely on this.
          return logout ? this.logout$() : EMPTY;
        } else {
          // Propagate the error that we don't know how to handle here.
          return throwError(() => e);
        }
      }),
    );
  }

  /**
   * Get Auth0 ID token. Verifies that the user is authenticated.
   *
   * @param options optional
   * @returns Observable
   */
  getIdToken$() {
    return this.auth0Client$.pipe(concatMap((client: Auth0Client) => from(client.getIdTokenClaims())));
  }

  getUrlToken() {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    return params.get('token');
  }

  /**
   * Begin to login user with Auth0 and thus our app.
   */
  login$(redirectPath = '/'): Observable<void> {
    const params = new URLSearchParams(window.location.search);

    const options: RedirectLoginOptions<AppState> = {
      appState: { target: redirectPath },
      authorizationParams: {},
    };

    // Use the connection from the url parameter if available, otherwise check the cookie
    const connection = params.get('connection') ?? this.getConnection();

    if (connection) {
      options.authorizationParams.connection = connection;
      this.setConnection(connection);
    }

    if (params.has('email')) {
      options.authorizationParams.login_hint = params.get('email');
    }

    return this.auth0Client$.pipe(concatMap((client: Auth0Client) => client.loginWithRedirect(options)));
  }

  /**
   * Log the user out off Auth0. Returns an observable that does not emit any values, just the complete notification.
   */
  logout$(returnTo: string = window.location.origin, removeConnection: boolean = true): Observable<never> {
    if (removeConnection) {
      this.removeConnection();
    }

    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => {
        client.logout({
          clientId: environment.auth.clientId,
          logoutParams: {
            returnTo,
          },
        });
        // Complete this observable. There will be observers listening for this complete notification.
        return EMPTY;
      }),
    );
  }

  /**
   * Log the user out off Auth0 silently.
   */
  logoutSilently$() {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) =>
        client.logout({
          clientId: environment.auth.clientId,
          openUrl: false,
        }),
      ),
    );
  }

  /**
   * Initialize the user. This includes getting the user's profile and getting the user's permissions.
   *
   * @param authenticated Is the user authenticated?
   * @returns boolean on whether we successfully initialized the user. If the user needs to reauthenticate explicitly, returns  {@link USER_EXPLICIT_REAUTH}.
   */
  protected initUser$(authenticated: boolean): Observable<boolean | typeof USER_EXPLICIT_REAUTH> {
    if (!authenticated) {
      return of(false);
    }

    // Get access token to get user's permissions. Handle possibilty where observable completes without emitting a value.
    const decode = true; // Get a decoded token, rather than the raw token that is a string.
    const logout = false; // We control when to log the user out.
    const getAccessToken$ = this.getAccessToken$({}, decode, logout).pipe(defaultIfEmpty<AccessToken, null>(null));

    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser())),
      switchMap(auth0User => zip(of(auth0User), getAccessToken$)),
      when(
        // No valid access token or no valid user. Can't really proceed with the rest of the pipe operators. We need the user to authenticate explicitly.
        ([auth0User, accessToken]) => !accessToken || !auth0User,
        map(() => USER_EXPLICIT_REAUTH),
      ),
      switchMap(values => {
        if (values === USER_EXPLICIT_REAUTH) {
          return of(USER_EXPLICIT_REAUTH);
        }

        return of(values as [Auth0User, AccessToken]).pipe(
          switchMap(([auth0User, accessToken]) =>
            // Make request to web-api/ to get user details e.g. userId, role.
            zip(of(auth0User), this.getByUsername$(auth0User.sub), of(accessToken)),
          ),
          switchMap(([auth0User, user, accessToken]) => {
            const userProfile: UserProfile = {
              ...auth0User,
              isAdmin: Boolean(user.isStaff),
              userId: user.id,
              permissions: accessToken.permissions,
            };

            // Update user's last login timestamp.
            // TODO: Unable to use UserService due to circular dependency. Need to look at a way to fix this.
            return this.httpClient
              .patch<User>(`${environment.server.apiURL}/users/${user.id}/`, { lastLogin: new Date().toISOString() })
              .pipe(
                first(),
                map(() => userProfile),
              );
          }),
          tap(userProfile => {
            this.userProfileSubject$.next(userProfile);
          }),
          map(() => true),
        );
      }),
    );
  }

  private getByUsername$(username: string): Observable<User> {
    // Unable to use UserService due to circular dependency.
    return this.httpClient.get<User[]>(`${environment.server.apiURL}/users/`, { params: { username } }).pipe(
      map((users: User[]) => {
        if (users.length === 0) {
          throw new Error(`User with username ${username} not found.`);
        } else if (users.length > 1) {
          throw new Error(`Multiple users with username ${username} found.`);
        }
        // Only expect 1 match.
        return users[0];
      }),
    );
  }

  private getConnection() {
    return sessionStorage.getItem('connection');
  }

  private setConnection(connection: string) {
    sessionStorage.setItem('connection', connection);
  }

  private removeConnection() {
    sessionStorage.removeItem('connection');
  }

  private getModalService(): BsModalService {
    return this.injector.get(BsModalService);
  }
}

/**
 * A user account's profile. Extends Auth0's User object (see {@link Auth0User}) with extra properties, from our web-api's User model.
 */
export interface UserProfile extends Auth0User {
  /**
   * web-api User model primary key.
   */
  userId: number;

  /**
   * Is user an admin user? This is a check against our web-api's User model and database.
   */
  isAdmin: boolean;

  /**
   * List of permissions of which web-api endpoints this user has access to.
   */
  permissions: string[];
}

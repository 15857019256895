<header class="modal-header">
  <h2>{{ title ?? 'Not Authorised' }}</h2>
</header>
<section class="modal-body">
  <p>{{ IS_DEMO_SITE ? "Let us help you map and assess your supply chain. Get in contact and we'll help you get your own account setup quickly" : "Sorry, you're not authorised to view this page" }}</p>
</section>
<footer *ngIf="!IS_DEMO_SITE" class="modal-footer">
  <button *ngIf="(this.authService.userProfile$ | async) === null" class="btn btn-primary" (click)="signIn()">Sign In</button>
  <button class="btn" [ngClass]="(this.authService.userProfile$ | async) === null ? 'btn-link' : 'btn-primary'" (click)="bsModalRef.hide()">Close</button>
</footer>
<footer *ngIf="IS_DEMO_SITE" class="modal-footer">
  <button class="btn btn-outline" (click)="bsModalRef.hide()">Back</button>
  <a class="btn btn-primary" href="mailto:info@fairsupply.com?subject=I'm%20interested%20in%20Fair%20Supply&body=Hi%20Fair%20Supply%2C%20%0A%0AI%20have%20a%20demo%20account%20and%20am%20interested%20in%20getting%20my%20own%20account%20so%20Fair%20Supply%20can%20map%20and%20assess%20my%20supply%20chain." target="_blank">Contact us</a>
</footer>

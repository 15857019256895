import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { AuthService } from 'src/app/core/auth/auth.service';
import { isDemoSite } from 'src/app/shared/util';

@Component({
  selector: 'app-not-authorised-modal',
  standalone: true,
  templateUrl: './not-authorised-modal.component.html',
  styleUrls: ['./not-authorised-modal.component.scss'],
  imports: [CommonModule],
})
export class NotAuthorisedModalComponent {
  title?: string;
  readonly IS_DEMO_SITE = isDemoSite;

  constructor(
    public authService: AuthService,
    public bsModalRef: BsModalRef,
  ) {}

  signIn() {
    this.authService.logout$().subscribe();
  }
}

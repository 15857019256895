// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  showStandard$ = new ReplaySubject<string>(1);

  showSuccess$ = new ReplaySubject<string>(1);

  showError$ = new ReplaySubject<string>(1);

  clearAll$ = new ReplaySubject<void>(1);

  showStandard(message: string) {
    this.showStandard$.next(message);
  }

  showSuccess(message: string) {
    this.showSuccess$.next(message);
  }

  showError(message: string) {
    this.showError$.next(message);
  }

  clearAll() {
    this.clearAll$.next();
  }
}

// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { Environment } from './environment.types';

export const environment: Environment = {
  production: false,
  type: 'development',
  server: {
    apiURL: 'https://development-api.fairsupply.io/api/v1',
    rssURL: 'https://development-api.fairsupply.io/rss-feed',
  },
  auth: {
    audience: 'https://api.fairsupplyanalytics-dev.com',
    clientId: 'zaLqVTqAJcE9YMUsR2arzayAcxOt9qVS',
    domain: 'fairsupplyanalytics-dev.au.auth0.com',
    namespace: 'https://fairsupplyanalytics-dev.com/roles',
  },
  defaultPageTitle: 'FairSupply - Dev',
  analytics: {
    ga: {
      enabled: true,
      id: 'G-NG1H0D0NK1',
    },
    gtm: {
      enabled: true,
      id: 'GTM-MQ39DKR',
    },
    segment: {
      enabled: true,
      id: '1Tzpi9MPu8B5NSm9JDo06XkYEeGX2wlj',
    },
    app: 'FairSupply',
  },
  launchDarkly: {
    clientSideId: '66e799b70ae37a0ff550b50c',
    streaming: true,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

/* eslint-disable @typescript-eslint/no-explicit-any */
import { OperatorFunction, of, switchMap } from 'rxjs';

type Predicate<T> = (x: T) => boolean;

/**
 * Switches to the provided sub-pipe when a predicate is met.
 *
 * This is particularly useful for switching to a fallback some way down the
 * pipe. Unlike `defaultIfEmpty`, this works for multiple emissions from the
 * source, and allows the fallback to be fetched asynchronously.
 *
 * ```
 * // Emit latest from xs, but fall back to ys if xs is empty.
 * event$.pipe(
 *   withLatestFrom(xs$),
 *   when(
 *     // This is the predicate
 *     xs => !xs.length,
 *     // This is the sub-pipe
 *     yService.get$(...),
 *     switchMap(...),
 *     map(...)
 *   )
 * );
 * ```
 */
export function when<T, A>(predicate: Predicate<T>, fn1: OperatorFunction<T, A>): OperatorFunction<T, T | A>;
export function when<T, A, B>(
  predicate: Predicate<T>,
  fn1: OperatorFunction<T, A>,
  fn2: OperatorFunction<A, B>,
): OperatorFunction<T, T | B>;
export function when<T, A, B, C>(
  predicate: Predicate<T>,
  fn1: OperatorFunction<T, A>,
  fn2: OperatorFunction<A, B>,
  fn3: OperatorFunction<B, C>,
): OperatorFunction<T, T | C>;
export function when<T, A, B, C, D>(
  predicate: Predicate<T>,
  fn1: OperatorFunction<T, A>,
  fn2: OperatorFunction<A, B>,
  fn3: OperatorFunction<B, C>,
  fn4: OperatorFunction<C, D>,
): OperatorFunction<T, T | D>;
export function when<T, A, B, C, D, E>(
  predicate: Predicate<T>,
  fn1: OperatorFunction<T, A>,
  fn2: OperatorFunction<A, B>,
  fn3: OperatorFunction<B, C>,
  fn4: OperatorFunction<C, D>,
  fn5: OperatorFunction<D, E>,
): OperatorFunction<T, T | E>;
export function when<T, A, B, C, D, E, F>(
  predicate: Predicate<T>,
  fn1: OperatorFunction<T, A>,
  fn2: OperatorFunction<A, B>,
  fn3: OperatorFunction<B, C>,
  fn4: OperatorFunction<C, D>,
  fn5: OperatorFunction<D, E>,
  fn6: OperatorFunction<E, F>,
): OperatorFunction<T, T | F>;
export function when<T, A, B, C, D, E, F, G>(
  predicate: Predicate<T>,
  fn1: OperatorFunction<T, A>,
  fn2: OperatorFunction<A, B>,
  fn3: OperatorFunction<B, C>,
  fn4: OperatorFunction<C, D>,
  fn5: OperatorFunction<D, E>,
  fn6: OperatorFunction<E, F>,
  fn7: OperatorFunction<F, G>,
): OperatorFunction<T, T | G>;
export function when<T, A, B, C, D, E, F, G, H>(
  predicate: Predicate<T>,
  fn1: OperatorFunction<T, A>,
  fn2: OperatorFunction<A, B>,
  fn3: OperatorFunction<B, C>,
  fn4: OperatorFunction<C, D>,
  fn5: OperatorFunction<D, E>,
  fn6: OperatorFunction<E, F>,
  fn7: OperatorFunction<F, G>,
  fn8: OperatorFunction<G, H>,
): OperatorFunction<T, T | H>;
export function when<T, A, B, C, D, E, F, G, H, I>(
  predicate: Predicate<T>,
  fn1: OperatorFunction<T, A>,
  fn2: OperatorFunction<A, B>,
  fn3: OperatorFunction<B, C>,
  fn4: OperatorFunction<C, D>,
  fn5: OperatorFunction<D, E>,
  fn6: OperatorFunction<E, F>,
  fn7: OperatorFunction<F, G>,
  fn8: OperatorFunction<G, H>,
  fn9: OperatorFunction<H, I>,
): OperatorFunction<T, T | I>;
export function when<T, A, B, C, D, E, F, G, H, I>(
  predicate: Predicate<T>,
  fn1: OperatorFunction<T, A>,
  fn2: OperatorFunction<A, B>,
  fn3: OperatorFunction<B, C>,
  fn4: OperatorFunction<C, D>,
  fn5: OperatorFunction<D, E>,
  fn6: OperatorFunction<E, F>,
  fn7: OperatorFunction<F, G>,
  fn8: OperatorFunction<G, H>,
  fn9: OperatorFunction<H, I>,
  ...fns: [OperatorFunction<any, any>]
): OperatorFunction<T, unknown>;

export function when(predicate: (x: any) => boolean, ...fns: [OperatorFunction<any, any>]): OperatorFunction<any, any> {
  return switchMap(source => {
    if (predicate(source)) {
      return of(source).pipe(...fns);
    } else {
      return of(source);
    }
  });
}

/**
 * Predicate that returns true if it is called with a thruthy value (e.g. `1`, `true`, `[]`).
 */
// export const itIsTruthy = <T>(x: T) => !!x;

/**
 * Predicate that returns true if it is called with a falsey value (e.g. `0`, `null`, `false`, `''`).
 */
export const itIsFalsey = <T>(x: T) => !x;

// export const itIsNullish = <T>(x: T) => x === null || x === undefined;

// @ts-strict-ignore
/* eslint-disable @typescript-eslint/no-explicit-any */
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Footprint, Mode } from 'src/app/assessment/assessment.model';
import { TransactionType } from 'src/app/dataset/dataset.model';
import { AlertService } from 'src/app/shared/alert.service';
import { convertValidationObject } from 'src/app/shared/util';
import { environment } from 'src/environments/environment';

export interface HasId {
  id?: number;
}

export interface Message {
  /**
   * Log message to appear in browser console. Contains technical details for developers, troubleshooting.
   */
  log?: string;
  /**
   * Nice (end-user friendly) message that will appear in the UI.
   */
  nice?: string;
}

@Injectable({
  providedIn: 'root',
})
export abstract class EntityService<T extends HasId> {
  // Quick way to enable/disable the showing of errors with the service
  showErrors = true;

  constructor(
    protected alertService: AlertService,
    protected httpClient: HttpClient,
  ) {}

  /**
   * Get all entities.
   *
   * @param params Optional HTTP params that can be passed to the GET request.
   * @returns List of entities.
   */
  getAll$(
    params:
      | HttpParams
      | { [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean> } = null,
  ): Observable<T[]> {
    const url = this.getWebApiEndpoint();

    this.validateParameters(params);

    const options = params ? { params } : null;
    // Can't pass null options into get(), error occurs.
    const req$ = options ? this.httpClient.get<T[]>(url, options) : this.httpClient.get<T[]>(url);

    return this.handleGetOrCreateResponse(req$, {
      log: `Get ${this.pluralIdentifier()} failed:`,
      nice: `Failed to get ${this.pluralIdentifier()}`,
    });
  }

  /**
   * Get all entities by primary keys.
   *
   * @param ids List of entities' primary keys.
   * @param key Optional key to use for the query param. Defaults to `"ids"`.
   */
  getAllByIds$(ids: number[], key: string = 'ids'): Observable<T[]> {
    const url = this.getWebApiEndpoint();
    // django-filter in web-api expects ids to filter on to be a string of comma-separated values.
    // This will also need a numbers_in filter added to the relevant django filter set
    const params = ids.length ? { [key]: ids.join() } : {};

    const req$ = this.httpClient.get<T[]>(url, { params });
    return this.handleGetOrCreateResponse(req$, {
      log: `Get ${this.pluralIdentifier()}(${ids}) failed:`,
      nice: `Failed to get ${this.pluralIdentifier()}`,
    });
  }

  getOne$(
    id: number,
    params:
      | HttpParams
      | { [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean> } = null,
  ): Observable<T> {
    // url of the form e.g. "api/users/<id>/"
    const url = this.getWebApiEndpoint(null, id);
    const options = params ? { params } : null;
    // Can't pass null options into get(), error occurs.
    const req$ = options ? this.httpClient.get<T>(url, options) : this.httpClient.get<T>(url);
    return this.handleGetOrCreateResponse(req$, {
      log: `Failed to retrieve ${this.singularIdentifier()} with ID(${id})`,
      nice: `Failed to retrieve ${this.singularIdentifier()}`,
    });
  }

  addOne$(entity: Partial<T>): Observable<T> {
    // url of the form e.g. "api/clients/"
    const url = this.getWebApiEndpoint();
    const req$ = this.httpClient.post<T>(url, entity);
    return this.handleGetOrCreateResponse(req$, {
      log: `Create new ${this.singularIdentifier()} failed:`,
      nice: `Failed to create ${this.singularIdentifier()}`,
    });
  }

  updateOne$(id: number, entity: Partial<T>): Observable<T> {
    // URL resource of the form e.g. "api/users/<id>/"
    const url = this.getWebApiEndpoint(null, id);
    const req$ = this.httpClient.patch<T>(url, entity);
    const message = {
      log: `Update ${this.singularIdentifier()}(${id}) failed:`,
      nice: `Failed to update ${this.singularIdentifier()}`,
    };
    return this.handleUpdateOrDeleteResponse(req$, message);
  }

  deleteOne$(id: number, body: Record<string, unknown> | null = null): Observable<null> {
    // URL resource of the form e.g. "api/users/<id>/"
    const url = this.getWebApiEndpoint(null, id);

    const options = body ? { body } : {};
    const req$ = this.httpClient.delete<null>(url, options);
    const message = {
      log: `Delete ${this.singularIdentifier()}(${id}) failed:`,
      nice: `Failed to delete ${this.singularIdentifier()}`,
    };
    return this.handleUpdateOrDeleteResponse(req$, message);
  }

  // TODO: what happens if group_by = []?
  getFootprint$<G extends string[] = undefined, F extends boolean = true>(
    id: number,
    mode: keyof typeof Mode = null,
    tier: number = null,
    type: keyof typeof TransactionType = null,
    params: FootprintOptions<G, F> = {
      group_by: undefined,
      footprint: true,
    },
  ) {
    const url = this.getWebApiEndpoint('footprint', id);

    const _params = {
      ...params,
      mode,
      type,
      ...(tier && { tier }),
    };

    this.validateParameters(_params);

    const req$ = this.httpClient.get<G extends undefined ? Footprint<[], F> : Footprint<G, F>[]>(url, {
      params: _params,
    });

    return this.handleGetOrCreateResponse(req$, {
      log: `Get footprint by ${this.singularIdentifier()}(${id}) failed:`,
      nice: `Failed to get footprint by ${this.singularIdentifier()}`,
    });
  }

  getDynamicFootprint$<G extends string[] = undefined, F extends boolean = true>(
    clientId: number,
    sectors: string[],
    spendAmount: number,
    type: keyof typeof TransactionType,
    currency: string,
    originCountry: string,
    mode: keyof typeof Mode,
    queryParams: FootprintOptions<G, F> = {
      group_by: undefined,
      footprint: true,
    },
  ) {
    const url = this.getWebApiEndpoint('dynamic-footprint');

    const params = { ...queryParams, clientId, sectors, spendAmount, type, currency, originCountry, mode };

    this.validateParameters(params);

    const req$ = this.httpClient.get<G extends undefined ? Footprint<[], F> : Footprint<G, F>[]>(url, { params });

    return this.handleGetOrCreateResponse(req$, {
      log: `Get footprint by ${this.singularIdentifier()} failed:`,
      nice: `Failed to get dynamic footprint by ${this.singularIdentifier()}`,
    });
  }

  getPaths$(id: number, params?: HttpParams | { [param: string]: string | string[] }): Observable<any> {
    const url = this.getWebApiEndpoint('paths', id);

    this.validateParameters(params);

    const req$ = this.httpClient.get<any>(url, { params });

    return this.handleGetOrCreateResponse(req$, {
      log: `Get paths by ${this.singularIdentifier()}(${id}) failed:`,
      nice: `Failed to get paths by ${this.singularIdentifier()}`,
    });
  }

  getErrorDetails(response: HttpErrorResponse): string[] {
    const err = ['Sorry! An unexpected error occurred. Please try again later.'];
    if (response.status === 500) {
      return err;
    }

    if (response.status === 400 && !response.error.detail) {
      const rv = convertValidationObject(response.error, false);
      return rv;
    }

    return [response.error?.detail || ''];
  }

  /**
   *
   * @param action REST action name e.g. `'get-all-by-company'`
   * @param resourceId Primary key ID `pk` of a single resource e.g. `'clients/<pk>/users/'`
   * @returns URL to resource endpoint.
   */
  protected getWebApiEndpoint(action?: string, resourceId?: number) {
    const _action = action ? `/${action}` : '';
    const _resourceId = resourceId ? `/${resourceId}` : '';

    return `${environment.server.apiURL}/${this.pluralIdentifier()}${_resourceId}${_action}/`;
  }

  protected handleResponse<V>(source: Observable<V>, message: Message, showError = true): Observable<V> {
    return source.pipe(
      catchError((error: HttpErrorResponse) => {
        const rv$ = throwError(() => error);

        if (!this.showErrors || !showError) {
          // At least tell devs when debugging.
          console.log('EntityService: Server responded with error but showing errors is disabled.');
          return rv$;
        }

        if (message?.log) {
          console.error(message.log);
        }

        this.alertService.showError(this.getErrorMessage(error, message));

        return rv$;
      }),
    );
  }

  // TODO: I Think both handleGetOrCreateResponse and handleUpdateOrDeleteResponse are the same so just use one, handleResponse()?
  protected handleUpdateOrDeleteResponse<V>(
    source: Observable<V>,
    message: Message = {},
    showError = true,
  ): Observable<V> {
    return this.handleResponse(source, message, showError);
  }

  protected handleGetOrCreateResponse<V>(
    source: Observable<V>,
    message: Message = {},
    showError = true,
  ): Observable<V> {
    return this.handleResponse(source, message, showError);
  }

  /**
   * Removes any parameters that are null or undefined.
   *
   * @param params Set of parameters to validate.
   */
  protected validateParameters(
    params:
      | HttpParams
      | { [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean> } = null,
  ) {
    for (const key in params) {
      if (params[key] === null || params[key] === 'null' || params[key] === undefined || params[key] === 'undefined') {
        delete params[key];
      }
    }
  }

  private getErrorMessage(error: HttpErrorResponse, message?: Message) {
    const title = message?.nice || 'An error has occurred.';
    const details = this.getErrorDetails(error);

    return `${title}\n${details.map(d => `• ${d}`).join('\n')}`;
  }

  abstract pluralIdentifier(): string;

  abstract singularIdentifier(): string;
}

/** Utility type to allow inference of the group by fields */
type GroupBy<G extends string[]> =
  | {
      group_by: G;
    }
  | Omit<object, 'group_by'>;

/** Utility type to allow inference of the metric fields */
type FootprintParam<F extends boolean> =
  | {
      footprint: F;
    }
  | Omit<object, 'footprint'>;

/**
 * Parameters object. All fields allowed, but has special handling for
 * - `group_by: string[]`
 * - `footprint: boolean`
 */
// Utility type to allow inference of the generic footprint type parameters
type FootprintOptions<G extends string[] = undefined, F extends boolean = true> = GroupBy<G> & FootprintParam<F>;
